import { useEffect } from "react";

const addBodyClass = className => document.body.classList.add(className);
const removeBodyClass = className => document.body.classList.remove(className);

export function useBodyClass(isActive, className) {
  useEffect(() => {
    if (isActive) {
      // Set up
      addBodyClass(className);
    }

    // Clean up
    return () => {
      removeBodyClass(className);
    };
  }, [isActive, className]);
}
